import { __ } from 'JIX/gettext.js';
import 'icons/button-visible.svg';
import 'icons/button-hide.svg';


export function setupPasswordField(id) {
    const password = document.getElementById(`password-${id}`);
    const togglePassword = document.getElementById(`togglePassword-${id}`);
    const toggleIcon = document.getElementById(`toggleIcon-${id}`).children[0];

    togglePassword.addEventListener('click', e => {
        e.preventDefault();
        if (password.type === 'password') {
            password.type = 'text';
            toggleIcon.setAttribute('href', '#svg-button-hide');
            togglePassword.setAttribute('aria-label', __('Skjul password'));
        } else {
            password.type = 'password';
            toggleIcon.setAttribute('href', '#svg-button-visible');
            togglePassword.setAttribute('aria-label', __('Vis password'));
        }
    });
}
